import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Jan2024() {
  return (
    <>
      <JournalEntry
        summary="Jan 15, 2024 - Sets with Matt"
        textContent={[
          "Played a few sets with Matt this morning and overall was satisfied with my play; lots of free points on my serve, returned well, and have made some improvement with my backhand lately helping to allow me to dictate rallies more often off that wing.",
          "I had a wider range in regards to the quality of shot on my forehand than I would like, but overall I was able to stay confident enough to commit to the stroke enough to prevent Matt from having easy offense too often.",
        ]}
        videos={[
          {
            title: "Sets with Matt",
            iframeTitle: "Jan 15, 2024 - Sets with Matt",
            videoId: "FlbzZZtncR8",
          },
        ]}
        notes={{
          fire: [
            "FH - Wrist held back at contact",
            "BH - swing the elbows + hands snap",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Jan 03, 2024 - Doubles Drill, Peter, Ball Machine X 2"
        textContent={[
          "Context for the slowmo forehand video - I was having my shoulders be responsible for the entire swing; coiling and uncoiling being the entirety of the preparation and bringing the racket to contact; keeping my arm and hand effectively 'stiff' (unmoving) relative to my shoulder; very similar to the feeling of actually catching and throwing back a medicine ball like you see the pros doing in warm ups.",
          "",
          "",
          "",
          "",
        ]}
        videos={[
          {
            title: "FH Slowmo",
            iframeTitle: "Jan 03, 2024 - FH Slowmo",
            videoId: "wBcRh9cAON8",
          },
        ]}
        notes={{
          fire: [
            "FH - shoulders coil/uncoil stiff arm & hand (medicine ball)",
            "BH - straight RHF + hands snap",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Jan2024;
