import { Grid, Skeleton } from "@mui/material";
import { memo, useState } from "react";
import styles from "./MyIframe.module.scss";

export interface MyIframeProps {
  hasBeenExpanded: boolean;
  title: string;
  iframeTitle: string;
  videoId: string;
}
const MyIframe = memo(function MyIframe({
  hasBeenExpanded,
  title,
  iframeTitle,
  videoId,
}: MyIframeProps) {
  const [loaded, setLoaded] = useState(false);

  return (
    <Grid item md={6}>
      {hasBeenExpanded && (
        <>
          <p>{loaded ? title : `${title} loading...`}</p>
          <iframe
            title={iframeTitle}
            width={"100%"}
            allow="fullscreen;"
            src={`https://www.youtube.com/embed/${videoId}`}
            onLoad={() => setLoaded(true)}
            className={loaded ? styles.showVideo : styles.hideVideo}
          />
          {loaded === false && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"250px"}
              height={155}
            />
          )}
        </>
      )}
    </Grid>
  );
});

export default MyIframe;
