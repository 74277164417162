import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import styles from "./JournalEntry.module.scss";
import { useState } from "react";
import MyIframe from "../my-iframe/MyIframe";

interface JournalEntryProps {
  summary: string;
  textContent: string[];
  videos?: {
    title: string;
    iframeTitle: string;
    videoId: string;
  }[];
  notes: {
    fire: string[];
    fireChanged: boolean;
    secondary: string[];
    secondaryChanged: boolean;
    proven: string[];
    provenChanged: boolean;
  };
}

function JournalEntry({
  summary,
  textContent,
  videos,
  notes,
}: JournalEntryProps) {
  const directEntryParam = new URLSearchParams(document.location.search).get(
    "entry"
  );
  const [dialogOpen, setDialogOpen] = useState(shouldOpenOnLoad());

  function shouldOpenOnLoad() {
    if (directEntryParam) {
      const formattedParam = directEntryParam.toLowerCase().replaceAll("-", "");
      const formattedSummary = summary
        .substring(0, 12)
        .toLowerCase()
        .replaceAll(" ", "")
        .replace(",", "");
      return formattedParam === formattedSummary;
    }
    return false;
  }
  const TextContent = () => {
    const text = textContent.map((paragraph, index) => {
      return (
        <Typography gutterBottom key={index}>
          <span dangerouslySetInnerHTML={{ __html: paragraph }} />
        </Typography>
      );
    });
    return <>{text}</>;
  };
  const Videos = () => {
    const vids = videos?.map(({ title, iframeTitle, videoId }, index) => {
      return (
        <MyIframe
          hasBeenExpanded={dialogOpen}
          title={title}
          iframeTitle={iframeTitle}
          videoId={videoId}
          key={`${videoId}-${index}`}
        />
      );
    });
    return <>{vids}</>;
  };
  const NotesList = (baseId: string, notes: string[]): JSX.Element => {
    let list = notes.map((listItem, index) => {
      return (
        <ListItem
          key={`${baseId}-${index}`}
          className={styles.noVertPadding}
          divider
        >
          <ListItemText>{listItem}</ListItemText>
        </ListItem>
      );
    });
    return <>{list}</>;
  };
  const NotesFire = () => NotesList("fire", notes.fire);
  const NotesNumerous = () => NotesList("secondary", notes.secondary);
  const NotesProven = () => NotesList("proven", notes.proven);

  return (
    <>
      <Grid item xs={12} md={6}>
        <Card
          variant="outlined"
          className={
            (videos?.length || 0) > 0
              ? `${styles.cardBackground} ${styles.entryHasVideo}`
              : styles.cardBackground
          }
          onClick={() => setDialogOpen(true)}
        >
          <CardHeader
            title={summary}
            disableTypography={true}
            action={
              <IconButton aria-label="open-dialog">
                <ExpandMoreIcon />
              </IconButton>
            }
          />
        </Card>
      </Grid>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <Typography>{summary}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setDialogOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextContent />
          <Grid
            container
            spacing={2}
            justifyContent={"space-evenly"}
            className={styles.videosContainer}
          >
            <Videos />
          </Grid>

          <Grid container spacing={1} className={styles.notes}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  className={
                    notes.fireChanged ? styles.backgroundHighlight : ""
                  }
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      marginTop: 0,
                      marginBottom: 0,
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>
                    <WhatshotIcon color="error" />
                    <WhatshotIcon color="error" />
                    <WhatshotIcon color="error" />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding dense>
                    <NotesFire />
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  className={
                    notes.secondaryChanged ? styles.backgroundHighlight : ""
                  }
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      marginTop: 0,
                      marginBottom: 0,
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Secondary</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding dense>
                    <NotesNumerous />
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  className={
                    notes.provenChanged ? styles.backgroundHighlight : ""
                  }
                  sx={{
                    "& .MuiAccordionSummary-content": {
                      marginTop: 0,
                      marginBottom: 0,
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Proven</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List disablePadding dense>
                    <NotesProven />
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default JournalEntry;
