import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Dec2023() {
  return (
    <>
      <JournalEntry
        summary="Dec 27, 2023 - Ball Machine Forehands Slowmo"
        textContent={[
          "By the end of practice, I had a few tweaks from my thoughts compared to yesterday.",
          "The first seven minutes was just my 'sheathe in front' cue, while the last three minutes or so was me adding the 7-to-1 contact.",
          "I essentially swapped out my hitting 'flat' cue from yesterday. The follow through on the first portion of hits has quite a bit of racket 'flip', maybe too much, but my shot quality was high - and I know Fritz has a lot more racket flip in his follow through as well.",
          "",
        ]}
        videos={[
          {
            title: "Ball Machine Forehands Slowmo",
            iframeTitle: "Dec 27, 2023 - Ball Machine Forehands Slowmo",
            videoId: "N76K_RI8WHo",
          },
        ]}
        notes={{
          fire: [
            "FH - sheathe in front + 7-to-1 contact",
            "BH - straight RHF + hands snap",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Dec 26, 2023 - Ball Machine Forehands"
        textContent={[
          "Hit with my ball machine for the first time in a while. A healthy portion of forehands and backhands for the first 90 minutes, and ended with 20 minutes of serves.",
          "I tried altering/adding some things to my forehand (such as having my off arm outstretched parallel to the baseline), but nothing 'new' was clicking for me today. By the end, 'trying to hit flat in front' was the mental cue that continued to produce consistent quality for me.",
        ]}
        videos={[
          {
            title: "Ball Machine Forehands",
            iframeTitle: "Dec 26, 2023 - Ball Machine Forehands",
            videoId: "phbjcRxsPaM",
          },
        ]}
        notes={{
          fire: [
            "FH - hook finger + in front + flat + in-to-out swing",
            "FH - wrist cocked up prep & swing",
            "BH - straight RHF + hands snap",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Dec 17, 2023 - Drills with Dan & Srini"
        textContent={[
          "From my thread post on my forehand, there had been a bunch of things pointed out; for todays practice session, I decided to focus on trying to drive through the ball more, and making contact further out in front.",
          "It felt pretty good on court, so I'll be curious to see if the guys think there is any notable difference in how my shots look today vs a few days ago.",
        ]}
        videos={[
          {
            title: "Side view",
            iframeTitle: "Dec 17, 2023 - Side view",
            videoId: "hAUvFilC2Bk",
          },
        ]}
        notes={{
          fire: [
            "FH - hook finger + in front + in-to-out swing",
            "FH - wrist cocked up prep & swing",
            "BH - right arm straight + closed stance",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Dec 16, 2023 - Drills with Dan, Doubles Drill"
        textContent={[
          "Forehand confidence is continuing to grow; especially on return of serve and low balls.",
          "I had posted on the forum asking for insight identifying issues with my forehand as it currently stands, and <b>ChaelAZ</b> had mentioned being interested in seeing what the stroke looks like from the side view; it had been a while since I had taken video from that angle, so I was curious to see how things looked.",
          "After watching over some of the footage, I think my preparation and start of the swing until contact looks decent, but holy smokes, from contact to the end of of my follow through is <i>way</i> too abrupt and shallow; my arm appears to be 'folding' in towards my body, possibly due to my elbow being too close to my body during the swing. I think it's also why the strings face the side fence in my follow through on most of my forehands, as opposed to facing the ground.",
          "I think I will start taking some side view footage more often.",
        ]}
        videos={[
          {
            title: "Side view",
            iframeTitle: "Dec 15, 2023 - Side view",
            videoId: "mw0z8ZtqPMo",
          },
        ]}
        notes={{
          fire: [
            "FH ? hook finger",
            "FH - wrist cocked up prep & swing",
            "BH - RHF + hands snap at contact + closed stance",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Dec 13, 2023 - 11s with Dan"
        textContent={[
          "Overall I hit really well today. At the start of practice I focused on trying to keep my FH elbow away in the prep phase, but I didn't end up finding a spot to place it that worked consistently for me.",
          "For most of the session my primary focus was on trying to hit 'line drives', to help reduce the unintended variation in the shape of shot I hit on the forehand side.",
          "Indirectly I think I ended up having good spacing between my elbow and forehand at contact for most of practice.",
          "When I hit a poor forehand, and then look at it frame by frame, almost every single time I'm making contact at the bottom of the string bed; so I need to try and figure out what 'thing/s' come into play that results in that happening.",
          "",
        ]}
        videos={[
          {
            title: "11s with Dan",
            iframeTitle: "Dec 12, 2023 - 11s with Dan",
            videoId: "taFTo4wgYEE",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up prep & swing",
            "FH ? pat the dog + hand path is ball path",
            "BH - RHF + hands snap at contact + closed stance",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Dec 11, 2023 - Dan x 2"
        textContent={[
          "More of the same - intentional, quality practice.",
          "After watching over the Dec 07 practice, I made note of how most of the forehands I hit poorly have my elbow tucked against my body at contact; with that in mind, my focus for our Dec 10 practice was to try to work on keeping my elbow away from my body, both in the prep phase and at contact.",
          "It makes sense, but I intuitively have much better spacing, coiling, and extension at contact when hitting inside-out forehands.",
          "I'm going to prioritize trying to keep my elbow out and away my next practice as well.",
          "There is still way too much unintentional variance in the flight path of my forehands as well as the depth, but taking a step back, it has been exciting seeing the gained confidence, consistency, and quality of forehands I'm hitting compared to even a few months ago.",
        ]}
        videos={[
          {
            title: "Dan Practice",
            iframeTitle: "Dec 07, 2023 - Dan Practice",
            videoId: "s2Lqo9y3EKo",
          },
          {
            title: "Drills with Dan",
            iframeTitle: "Dec 10, 2023 - Drills with Dan",
            videoId: "iiyJ9Bmtzzw",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up prep & swing",
            "FH - elbow out 'left'",
            "FH - hit the bottom 'edge' of the ball",
            "BH - make hands 'snap' at contact",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Dec 02, 2023 - Kris, Dan, Doubles Drill & Doubles"
        textContent={[
          "I experimented too much on my forehand when hitting with Kris and Dan this week; I strayed too far from my fire note that had been the reason I had been hitting my forehand cleaner lately. But, I at least noticed it was happening, and was able to rein the experimenting back in for doubles drill and a doubles match today.",
          "I'm not thrilled with my backhand, mostly the lack of offense I have with it, but I think trying to improve it at the same time as my forehand has been overloading my things to focus on while out on court, so I'm going to try and leave the backhand alone for the moment.",
        ]}
        videos={[
          {
            title: "Set with Kris",
            iframeTitle: "Nov 29, 2023 - Kris Set",
            videoId: "s6iqk1JoVdI",
          },
          {
            title: "Dan Practice",
            iframeTitle: "Nov 30, 2023 - Dan Practice",
            videoId: "qCUmbEZC_WE",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up prep & swing",
            "FH ? vertical drop into slot",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Dec2023;
