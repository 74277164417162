import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Nov2023() {
  return (
    <>
      <JournalEntry
        summary="Nov 27, 2023 - Dan"
        textContent={[
          "My main focus was on trying to add to my forehand consistency - surprise, surprise.",
          "I had a lot of mishits off the bottom of the frame on forehands when the ball was especially low; I'll try either bending my knees more, or switching to having more of a lifting swing next time.",
          "Dan has been trying to implement drop shots more lately, and today I joined in on that, and had notably better feel than I remember the last time I tried drop shots.",
        ]}
        videos={[
          {
            title: "Dan",
            iframeTitle: "Nov 27, 2023 - Dan",
            videoId: "qAvifhUoeV8",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist stays back",
            "FH - prep to pat the dog",
            "FH - hand stays inside ball-body line",
            "BH - closed stance over shoulder",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 24, 2023 - 11s with Dan"
        textContent={[
          "Lots of experimenting with the forehand, probably too much today, resulting in my forehand fading in and out during todays practice. I think next practice I will make an effort to 'reset' back to my new default forehand once I feel like my trying different things starts leading me astray.",
          "Most of my experimenting on the forehand today was in regards to extension; both in the prep phase, and at different contact points.",
          "On my backhand side, I was focusing on using weight transfer to create most of the pace of my shot, instead of trying to swing faster. In addition to that, I've noticed that I have been framing the ball far too often when I try to hit my backhand strong cross court, so I was intentional about not trying to create such a strong angle on backhands today, and I noticed better average quality of shot as well as less framing.",
          "Also, my high forehands are just embarrassing; continuing to feel that shot out.",
        ]}
        videos={[
          {
            title: "11s with Dan",
            iframeTitle: "Nov 24, 2023 - 11s with Dan",
            videoId: "n9MLGSOKD3A",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up (tip pointing up) prep & stroke",
            "FH - elbow max away at contact",
            "BH - step forward or around",
          ],
          fireChanged: false,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 23, 2023 - Drills & Set with Dan"
        textContent={[
          "Lots of quality reps today. Forehand is continuing to feel trustworthy.",
          "I think I'm on to something in regards to my elbow on the forehand - at contact, trying to keep my elbow as far from my body as possible seemed effective. I'm not sure yet whether it's more effective to have it far away in the prep phase, or just at contact, but this seems like it could be the next notable addition to my forehand progression.",
          "I'm also starting to use my flat serves more in sets; even though I haven't spent much time working on it specifically, I've been hitting it pretty consistently already.",
          "",
        ]}
        videos={[
          {
            title: "Drills & Set with Dan",
            iframeTitle: "Nov 23, 2023 - Drills & Set with Dan",
            videoId: "CYT9PTXzoGQ",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up (tip pointing up) prep & stroke",
            "FH - elbow max away at contact",
            "BH - step forward or around",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 21, 2023 - Drills with Dan"
        textContent={[
          "I'm still liking the fire note of having the tip pointing up on my forehand preparation. I spent this practice sessions experimenting with other pieces of the prep and stroke. Nothing felt consistent enough to take definite note of.",
          "I've been experimenting with my backhand a bunch as well, and the same story as the forehand, nothing for sure worth keeping around.",
          "Still arming the forehand way too much.",
        ]}
        videos={[
          {
            title: "Drills with Dan",
            iframeTitle: "Nov 21, 2023 - Drills with Dan",
            videoId: "ubgD5uWvo3w",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up (tip pointing up) prep & stroke",
            "Flat Serve - 30% arm slap",
          ],
          fireChanged: false,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 15, 2023 - Sets with Kris"
        textContent={[
          "Super pleased with my forehand today; my <i>fire</i> forehand note that was working well in my last practice with Dan worked even better for me. It even felt great when returning serve which is a big deal compared to my usual shaky return on that side.",
          "My other <i>fire</i> note for my flat serve also worked well today, and is very easy for me to repeat.",
          "I'm continuing to experiment with my backhand; variations I've been trying lately have all had relatively similar results, so I'll keep tweaking it to try something that feels even better.",
          "Back to my forehand - I'm still not utilizing my body enough; my arm is still doing too much of the work. I am also swinging with a t-rex arm way too much, where my elbow is almost touching my body during the forward swing. My hope is that when I figure out how to use my arm less, both issues might be resolved at the same time.",
        ]}
        videos={[
          {
            title: "Sets with Kris",
            iframeTitle: "Nov 15, 2023 - Sets with Kris",
            videoId: "DRjckiQeHz8",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up (tip pointing up) prep & stroke",
            "Flat Serve - 30% arm slap",
          ],
          fireChanged: true,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 13, 2023 - Dan"
        textContent={[
          "As I noted in my last post, I realized I have been trying to 'swing' low to high way too intentionally/drastically on my forehand. A few of <b><i>The Tennis Doctor</i></b>'s forehand videos have really hammered home how the hand and wrist should be at the end of the preparation phase, as well as explicitly calling out how trying to swing low to high is wrong and detrimental to hitting a modern forehand.",
          "Looking at his forehand, <b><i>Winston Du</i></b>'s forehand, and a few pros, it was very apparent that on regulation forehands they do not point the racket tip downwards <i>nearly</i> as drastically as I find myself doing; their swing path is much less low to high.",
          "With that in mind, my goal today on the forehand was to try to and have my wrist and hand set the way <b><i>The Tennis Doctor</i></b> explains and shows in his videos.",
          "I was pretty demoralized trying to have it feel good during mini tennis and the first part of our practice, but definitely started to find enough of a rhythm with it to be hitting some higher quality forehands than usual, that felt great and trustworthy, while (mostly) keeping the consistency relatively high.",
          "There were still a lot of variables to the swing that I experimented with during this session to try to figure out how best to adjust for different ball heights, and trying to increase consistency, but overall I left the practice feeling pretty hopeful. Those are my thoughts before watching over the practice.",
          "<b>Thoughts after Watching Over the Practice</b>",
          "I was really struggling with getting a hang of it during the warmup, but it didn't take me too long to start hitting a quality ball pretty consistently.",
          "I was arming the ball on way too many forehands, but the swing path and follow through looked better than usual even on those. I did not really figure out how best ot adjust for high balls though.",
          "All in all, these results look pretty promising.",
        ]}
        videos={[
          {
            title: "Dan Practice",
            iframeTitle: "Nov 13, 2023 - Dan Practice",
            videoId: "M6lS1I-jUOI",
          },
        ]}
        notes={{
          fire: [
            "FH - wrist cocked up (tip pointing up) prep & stroke",
            "FH - hand height at prep & hand path determine flight",
            "BH - conti grip + fast swing touch back",
            "Flat Serve - 30% arm slap",
          ],
          fireChanged: true,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 10, 2023 - Kris"
        textContent={[
          "Played a few sets with Kris this morning, although I only have video from a portion of it.",
          "It was pretty scrappy from both of us. He recently took a lesson to work on his serve, and trying to implement it during match play is always tough. For me, this was my first hitting session using my racket strung with <b><i>Gosen Polylon</i></b> in my crosses. I was able to tell I was getting less spin (as expected) than usual, but contact felt really nice, and I was getting extra depth on my strokes in place of the spin. It felt great serving, but I definitely had a hard time trying to adjust my forehand (although that's a constant problem for me).",
          "I actually hit some flat first serves today and felt really good rhythm, so hopefully I can find it again moving forward.",
          "I was still very 'army' on the forehand today; I'm especially bad when returning serve - although I'm trying to be more aggressive on my forehand returns, so I'm making a even more errors than usual right now.",
        ]}
        videos={[
          {
            title: "Set(s?) with Kris",
            iframeTitle: "Nov 10, 2023 - Set(s?) with Kris",
            videoId: "gLPV23AijAQ",
          },
        ]}
        notes={{
          fire: [
            "FH - loose closed rafa",
            "BH - eastern tip down",
            "Flat Serve - 30% arm slap",
          ],
          fireChanged: true,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 09, 2023 - John, Dan"
        textContent={[
          "I hit with a new guy, John, that I met through Dan. Solid all around, offensive mindset. We started with two hand-fed 11-point games, and then played best of three sets, with me taking it, all three pretty lopsided set scores in 6-2, 0-6, 6-1.",
          "The first set he had a lot of unforced errors, and completely flipped that the second set and had almost none. My forehand was hit or miss, but I stayed committed to keeping my racket speed up throughout. The plan was to take video, but I figured I would defer it until our next time we hit.",
          "Another day of practice games with Dan - volleys, overheads, full court, and cross court games.",
          "After watching over this practice, I noticed how badly I'm still arming the ball, so I'm going to focus on trying to prevent that from happening while still hitting the ball with conviction.",
        ]}
        videos={[
          {
            title: "Practice Games with Dan",
            iframeTitle: "Nov 09, 2023 - Practice Games with Dan",
            videoId: "S59bWdw-PpA",
          },
        ]}
        notes={{
          fire: [
            "FH - early rafa + explode + open stance",
            "BH - closed stance",
          ],
          fireChanged: true,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 07, 2023 - Dan - Practice & Set"
        textContent={[
          "Another good practice with Dan. Among our hand-fed games to 11 we like to play, we also spent some time playing out points with me serving and volleying.",
          "I had more mishits off the forehand than I would have liked, so I'm going to be trying to focus more on hitting through the ball and staying away from trying to brush the ball as my main mental thought.",
        ]}
        videos={[
          {
            title: "Practice & Set with Dan",
            iframeTitle: "Nov 07, 2023 - Practice & Set Dan",
            videoId: "xCrYIHoGCiU",
          },
        ]}
        notes={{
          fire: [
            "FH - early loop rafa + 90% RHS + open stance",
            "(High - closed strings + more vert rafa)",
            "(Low - lift)",
            "BH - straight arms + RH eastern",
          ],
          fireChanged: true,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Nov 04, 2023 - Kris, Dan"
        textContent={[
          "Played a few sets against Kris and I was happy with how I was able to adjust my forehand (mainly the size of my loop) against his larger variety of pace from his groundstrokes. My backhand was on point as well, so I was able to control most of the rallies that got beyond a few shots.",
          "Hand-fed games with Dan went decently - I normally use my strings until they snap, but today was the first time during a hitting session where I continued to be surprised by the lack of spin I was getting on a lot of strokes that felt really good, and as our practice went on I had less and less confidence in my shots. Once I got home I checked my strings and noticed my mains were notched through most of the way. I'm going to start cutting my strings out once they are worn down badly, and not suffer the reduced playability just to save a few bucks.",
        ]}
        videos={[
          {
            title: "Practice with Dan",
            iframeTitle: "Nov 04, 2023 - practice with Dan",
            videoId: "5agRDkbHomY",
          },
        ]}
        notes={{
          fire: [
            "FH - loop rafa + 90% RHS + open stance",
            "(High - closed strings + more vert rafa)",
            "(Low - lift)",
            "BH - push top hand into bottom hand",
          ],
          fireChanged: true,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Nov2023;
