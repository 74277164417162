import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Jun2023() {
  return (
    <>
      <JournalEntry
        summary="Jun 19, 2023 - Dan, Men's 4.0 drill"
        textContent={[
          "Dan and I did some drills followed by playing a set, which he managed to take 6-4. The 'lefty serve' benefit was much less noticeable for me, and my forehand was a hot mess. Even still, I didn't have a ton of unforced errors; Dan hits smart shots, and is great at redirecting, especially redirecting down the line; I can't remember the last time a righty was able to so easily go down the line with their forehand against me.",
          "I might have noticed something on my forehand - as the hitting/match session goes on, I think I unconsciously shift my grip further towards western; so I'm going to put some emphasis on starting with a weaker semi-western grip and trying to make sure I stay there as my session goes on.",
          "",
          "",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - thumb knuckle top bevel 2, pointer knuckle bevel 4",
            "BH - bottom hand pointer knuckle bevel 2",
          ],
          fireChanged: true,
          secondary: [
            "Serve - tip gets low",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Jun 13, 2023 - Mixed 4.0 drill, Dan, Matt"
        textContent={[
          "First hitting session with both Dan and Matt who I met at the 4.0 group drill I attended last week. Both sessions went - Mini tennis, hand-fed point play, followed up by playing two sets.",
          "I was pretty happy with my level of play both matches; my serve earned me plenty of free points, and I was able to find decent rhythm with my forehand.",
          "I'm continuing to work towards integrating a loop into my forehand. I'm also going to spend time time shifting my top hand on my 2HBH to more of a semi-western grip, as opposed to the eastern I usually have it at - while I enjoy hitting more flat with my backhand, I'm too often hitting backhands with zero spin, which ends up resulting in me trying to guide the stroke too much.",
          "I made a bit of a mental shift during my matchplay with Matt - I usually default to assuming my opponents backhand will be a weapon, which leads to me trying to hit with a lot more pace than I need to into that side of the court. The result is that I swing too fast (for my ability) resulting in a lot more errors off of both forehand and backhand.",
          "Which leads me to my new outlook of defaulting to assuming I can hit slow to the opponents backhand, until they show me otherwise.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "GS - hit slow to BH",
            "FH - open stance loop top of strings",
            "BH - top hand semi western grip golf swing",
          ],
          fireChanged: true,
          secondary: [
            "Serve - tip gets low",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Jun 03, 2023 - Group drill, Pete"
        textContent={[
          "I attended a 4.0 Men's drill this morning and was pleasantly surprised by the overall quantity and quality of guys who showed up.",
          "On top of that I managed to make a few new contacts, and hopefully more moving forward as I plan on attending this drill again.",
          "I also hit this afternoon with my buddy Pete who is pretty new to tennis. At the moment all reps are good reps as I get closer and closer to the timing I had on my strokes before I moved.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - loop with fully loose hand",
            "BH - tip in water low/high",
          ],
          fireChanged: true,
          secondary: [
            "Serve - tip gets low",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Jun2023;
