import JournalEntry from "../../shared/journal-entry/JournalEntry";

function May2023() {
  return (
    <>
      <JournalEntry
        summary="May 27, 2023 - Moved"
        textContent={[
          "This month has been a busy one, as my girlfriend and I made the move back to Minnesota.",
          "The first few weeks of May were mostly consumed by getting moved in and adjusted. We are renting in a new area, which meant finding new tennis partners, and a new indoor tennis facility.",
          "Over the last week or so I managed figure out what tennis facility made the most sense for me, and through that, found a group of guys to hit with.",
          "I made time for two hitting sessions so far, mostly just focused on finding my timing with my strokes again.",
          "The silver lining of having a few weeks away from tennis was that I had some time for my lingering (jumpers) knee to heal more.",
          "I expect the next month or two to be sporadic as I see about meeting a few more guys in the group I got associated with and figuring out who has what availability and interest in hitting regularly as the summer goes on.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - whole arm/hand/wrist is single unit, wiper swing",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          fireChanged: true,
          secondary: ["BH - hands very close"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default May2023;
