import {
  Feb2023,
  Jan2023,
  Dec2022,
  Mar2023,
  Apr2023,
  May2023,
  Jun2023,
  Jul2023,
  Aug2023,
  Sep2023,
  Oct2023,
  Nov2023,
  Dec2023,
  Jan2024,
  Feb2024,
  Mar2024,
  May2024,
} from "./entriesByMonth";

function Journal() {
  return (
    <>
      <May2024 />
      <Mar2024 />
      <Feb2024 />
      <Jan2024 />
      <Dec2023 />
      <Nov2023 />
      <Oct2023 />
      <Sep2023 />
      <Aug2023 />
      <Jul2023 />
      <Jun2023 />
      <May2023 />
      <Apr2023 />
      <Mar2023 />
      <Feb2023 />
      <Jan2023 />
      <Dec2022 />
    </>
  );
}
export default Journal;
