import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container, Grid } from "@mui/material";
import styles from "./App.module.scss";
import Header from "./components/header/Header";
import Journal from "./components/journal/Journal";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiAccordion: {
      defaultProps: {
        sx: {
          border: "1px solid",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className={styles.app}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Header />
            </Grid>
            <Journal />
          </Grid>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
