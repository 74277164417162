import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import styles from "./Header.module.scss";

function Header() {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Card variant="outlined" className={styles.Header}>
      <CardContent>
        <Grid container spacing={2} justifyContent={"space-around"}>
          <Grid item lg={8}>
            <div className={styles.textSection}>
              <h1>My Tennis Journal</h1>

              <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    Welcome to my Tennis Journal - a place for me to record all
                    things tennis...
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography gutterBottom>
                    I found my passion for tennis my last year of college. A
                    buddy was the captain of our club team, and was confident he
                    could beat me. I joined the club making it my goal to beat
                    him by the end of the year.
                  </Typography>
                  <Typography gutterBottom>
                    Not too long after starting, I fell in love with the game,
                    and it has continued to be my main sport to watch and play
                    ever since.
                  </Typography>
                  <Typography gutterBottom>
                    I continued to play for a few years after graduating - I was
                    asked to join a 4.0 USTA team and played mostly singles for
                    two sessions, and got bumped to 4.5 NTRP. I was and still am
                    focused on improving my game, whereas most people in the
                    4.0+ league environment are hyper focused on winning. I
                    ended up getting burnt out, and it resulted in me stepping
                    away from playing tennis for a few years.
                  </Typography>
                  <Typography gutterBottom>
                    Eventually my desire to be back on the court (
                    <i>and desire to stay somewhat 'in shape'</i>) was
                    rekindled, and I've been back on the courts ever since.
                  </Typography>
                  <Typography gutterBottom>
                    Only recently did it occur to me to use my web dev skill set
                    to start a tennis journal to record my notes-to-self as they
                    change or solidify, consolidate my match play & play-by-play
                    videos, and whatever other tennis content comes to mind.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
          <Grid item md={4} className={styles.pictureContainer}>
            <img
              className={expanded ? styles.largePicture : styles.smallPicture}
              src="IMG_20230331_094120-min.jpg"
              alt="Dakota Castleberg"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default Header;
