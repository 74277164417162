import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Feb2023() {
  return (
    <>
      <JournalEntry
        summary="Feb 25, 2023 - Jim drill, Demos with Greg"
        textContent={[
          "Jim drill - quality practice; full session with the ezone; decided I should start using/working on my first serve, so I hit flat first serves during our serve/return drills; serve percentage wasn't great, but better than I expected it to be for not ever hitting flat serves.",
          "Demos with Greg - I did not like hitting with the Pure drive; I felt it gave a little more easy power, but at a notable cost in control and consistent launch angle. The Pro Staff was decent for me, excelling on slices, but nothing else stood out.",
          "I ordered another round of demos, of course with the ezone again, and:",
          "Solinco Whiteout 305",
          "Tecnifibre TFight 300 RS",
          "Had a few forehand things working for me and updated notes.",
        ]}
        videos={[
          {
            title: "Rally practice with Demos",
            iframeTitle: "Feb 25, 2023 - rally practice with Demos",
            videoId: "03j2am1EPuk",
          },
        ]}
        notes={{
          fire: [
            "FH - top of strings, be long",
            "FH - stepping in OR serious body rotation prevents racket roll-over",
            "Flat Serve FH Return - pop and stop",
          ],
          fireChanged: true,
          secondary: ["Volley - watch contact in front"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Feb 20, 2023 - Lots of Hits & Racket Demos"
        textContent={[
          "I've had a bunch of hitting sessions since the last post, with the familiar partners and drills.",
          "I've continued to demo more rackets, most fitting the 'tweener' category, bringing the list to:",
          "Yonex EZONE 98 (305g)",
          "Head Extreme Tour",
          "Head Extreme MP",
          "Yonex Vcore 98",
          "Yonex Vcore Pro 97",
          "With two more on the way:",
          "Wilson Pro Staff 97 v13",
          "Babolat Pure Drive 2021",
          "TennisNerd recommends treating a racket demo like a first date - if it doesn't feel like a good fit, it probably won't get better with a second date.",
          "Right from the start, I really liked the EZONE 98, and have continued to like it throughout all three consecutive weeks of demo shipments in a row. Out of the five rackets I've demoed so far, it is the only A rating experience I've had.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - NEED TO PREVENT RACKET FROM ROLLING OVER",
            "FH - relaxed wrist prevent roll over?",
            "BH - right hand dominant",
            "1st Serve Return - pop and stop",
          ],
          fireChanged: true,
          secondary: ["Volley - watch contact in front"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Feb 07, 2023 - Jim Drill, Greg Sets, Doubles Drill, Doubles night"
        textContent={[
          "Jim Drill - quality reps, finding some trust in my backhand again. My approach volleys are improving.",
          "Greg Sets - (un)fortunately all of Greg's rust from years of not playing have worn off. At this point he hits a pretty flat shot on both wings, making it very difficult to hit through him. unless I manage to run him during the point, he is also able to get on balance to almost every approach shot I hit to his forehand especially. He took the first set, I took the second. By the end of play, the simple strategy of hitting mostly to his weaker side is the only consistent option I have, so I'll be targeting it much more so in sets moving forward.",
          "Doubles Drill - got invited to sub in for a doubles drill with Jim and two juniors. Both juniors were good, so it was much easier to maintain high intensity and focus than some of my other time on court. I played well; definitely hoping I can get myself involved in more court time with better players.",
          "Doubles night - Played well especially with my approach and net game. I had some unforced forehand errors when receiving weak deep shots to my forehand; until I get more comfortable flattening out my forehand, the pressure of the net guy will continue to be an issue for me.",
          "The two demo rackets I ordered should be here sometime this week.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - no backswing = better aim",
            "BH - keep wrists up - drop/brush will happen on its own",
            "Return - split step = higher quality contact",
          ],
          fireChanged: true,
          secondary: ["Volley - straight arms, turn body"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Feb2023;
