import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Aug2023() {
  return (
    <>
      <JournalEntry
        summary="Aug 10, 2023 - A lull in practice time"
        textContent={[
          "Summer has gotten busy! I've had between zero and two sessions on court per week since my last update.",
          "Although it's been less time than I would prefer, I feel like I've been able to make some small improvements with my serve (flat & slice mostly) and backhand.",
          "My buddy Brett uses a western forehand, and after getting a chance to hit with him recently, I decided I would try working with that grip for a while.",
          "I have recently self-diagnosed as having gluten intolerance and have noticed an improvement in my stamina (call me Joker).",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - western + fully loose hand + open stance + 'sheathe'",
            "BH - wrists stay back",
          ],
          fireChanged: true,
          secondary: [
            "Serve - jump",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Aug2023;
