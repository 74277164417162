import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Jul2023() {
  return (
    <>
      <JournalEntry
        summary="Jul 07, 2023 - Men's 4.0 drill, Matt, Brett"
        textContent={[
          "Between out of town weekends and people being busy around July 4th holiday, I've had less time on court lately.",
          "Overall I have been feeling pretty decent with my game for not being on court as much as I would like.",
          "I also might experiment with hitting a 1hbh."
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - choke up so hand rests against inside bottom lip",
            "BH - loop + wrists back",
          ],
          fireChanged: true,
          secondary: [
            "Serve - tip gets low",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Jul2023;
