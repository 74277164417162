import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Apr2023() {
  return (
    <>
      <JournalEntry
        summary="Apr 29, 2023 - Forehand Analysis Feedback"
        textContent={[
          "Court time - Greg, Pat drill, Greg, Tom Drill",
          "<br />",
          "My notes on Nik's analysis of my forehand:",
          "<br />",
          "<b>The Good</b>",
          "<li>forward swing path</li>",
          "<li>contact point</li>",
          "<br />",

          "<b>The Bad</b>",
          "<li>arm does too much work</li>",
          "<li>not enough shoulder turn by end of prep phase</li>",
          "<li>racket drop - not a continuous motion, creates inconsistency</li>",
          "<li>lack of intensity - flat-footed too often, stance too narrow</li>",
          "<br />",

          "<b>Notes</b>",
          "<li>use more shoulder turn - 'show your back' - increased range of motion allows more space to generate racket head speed</li>",
          "<li>racket drop needs to be back and down, not just straight down; it should feel continuous and allow gradual acceleration; HAND should loop but I do not need to change racket orientation</li>",
          "<li>wider base, don't be flat-footed</li>",
          "<li>load left leg - should have a slight 'V' from torso to upper leg</li>",
          "<li>body should begin opening just before forward swing</li>",
          "<li>if contact is late due to proper should turn, I might need to open up my shoulders sooner</li>",
          "<li>expect to have to adjust timing of stroke as I begin to improve shoulder turn and incorporating more of a loop</li>",
          "<br />",
          "Hitting with Greg last night was my first time on court after I had reviewed the analysis. I tried working on both shoulder turn and looping at the same time, and that certainly was not successful. I spent most of the session switching off between trying out more shoulder turn and trying to use more of a loop, and will continue only trying one at a time.",
          "Towards the end of Tom's drill I was feeling good with treating my arm as a single unit and swinging in a 'wiper' fashion.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - whole arm/hand/wrist is single unit, wiper swing",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          fireChanged: true,
          secondary: ["BH - hands very close"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Apr 22, 2023 - Jim, Greg, Tom drill, Forehand Video for Analysis"
        textContent={[
          "I took some video of my forehand when I hit with Jim and sent it Nik's way, and am currently awaiting his analysis.",
          "I'm two sessions into using <b>Weiss Canon Ultra Cable</b> on my mains; the spin was unreal the first hour or so, but has seemed to feel comparable to my other spin polys the last few hours.",
        ]}
        videos={[
          {
            title: "Video for Forehand Analysis",
            iframeTitle: "Mar 17, 2023 - Video for Forehand Analysis",
            videoId: "O-WxE2K6_eI",
          },
        ]}
        notes={{
          fire: [
            "FH - low, fully-loose hand prep",
            "BH - right hand is the boss",
          ],
          fireChanged: true,
          secondary: ["FH - hand closer to body at end of prep?"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Apr 16, 2023 - Andrea X 2, Greg"
        textContent={[
          "Nothing noteworthy with technique from the last few sessions.",
          "I decided to pull the trigger on trying video analysis from <b>Nik at Intuitive Tennis</b>, so I'll be taking some video this week to send his way and see if he has thoughts on what's preventing me from finding consistency on my forehand.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - early, fully-relaxed prep",
            "FH - Grip strength as low as possible",
            "FH - Hit 'flat', only slight acceleration",
            "BH - top-hand elbow finishes high in front",
          ],
          fireChanged: true,
          secondary: ["Slice - wrist always locked"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Apr 07, 2023 - Greg, Jim, Greg, Focus Points"
        textContent={[
          "I have been continuing my focus on trying to figure out how to offset the effect tension has on my groundstrokes when in a desire-to-win scenario.",
          "What has been feeling good the last few sessions on the forehand is primarily <i><b>keeping my grip strength as low as possible</b></i>, so that will stay the focus on the forehand for now; the hope being that if I don't inject tension into my arm via squeezing the racket too hard, there should be much less tension that can creep in during matches.",
          "On the backhand side, focusing on having my top-hand elbow finish high and in front was working well for consistent depth and pace.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - Grip strength as low as possible",
            "FH - Hit 'flat', only slight acceleration",
            "BH - top-hand elbow finishes high in front",
          ],
          fireChanged: true,
          secondary: ["Slice - wrist always locked"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Apr 02, 2023 - UTR matches recap"
        textContent={[
          "Well, my play was a mixed bag, but overall I'm satisfied with how the matches went, for being my first 'rated' matches/event in years.",
          "My serve, volleys, and backhand slice all held up to my 'practice' level; as I mentioned in my previous post, if needed my plan was to get to the net often, and that ended up being the case for both of my matches. I volleyed very well, so moving forward I will have confidence moving forward when I get the chance.",
          "Unfortunately, BOTH of my groundstrokes were horrendous compared to my 'practice' groundstrokes; it seems that when nerves and/or a desire-to-win are involved, extra tension gets added to my arms during my groundstrokes, even though it does not <i>feel</i> like it in real-time.",
          "The effect of the added arm tension seems to result in <b>(1)</b> making contact WAY lower on my string bed than I perceive, and <b>(2)</b> throwing off my stroke timing.",
          "My primary focus moving forward will be trying to figure out how to best offset those two points.",
          "<a href='https://app.universaltennis.com/events/160916?d=f55bd3dd-db10-496a-bb26-07c146716d13&t=3' target='_blank'>If you have a UTR account check out my match results</a>",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - loop + top of strings + hit long",
            "BH - loop + slight topspin grip + wrists stay up",
          ],
          fireChanged: true,
          secondary: ["Slice - wrist always locked"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Apr2023;
