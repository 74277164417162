import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Mar2023() {
  return (
    <>
      <JournalEntry
        summary="Mar 31, 2023 - ball machine"
        textContent={[
          "I wanted to get some more reps in as preparation for my UTR FAST4 matches this weekend, and I used my ball machine for the session.",
          "I feel like my forehand has wavered lately, or at least I have noticed that I have had a hard time adapting my swing to different opponents, which is not an issue for my backhand.",
          "I had some mental cues that felt good for the forehand, so I figure I will start with those for my matches and see how it goes.",
          "For my backhand, I brought back the cue of keeping my wrists up throughout the swing which seemed to add a lot of consistency, and shifting to a slightly more topspin grip seemed to produce consistent shots that had more useful topspin than the completely flat backhands I have been hitting lately.",
          "Depending on how my forehand holds up for my matches, I might shift my strategy to more actively forcing my way to the net.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - hand moves 1\u00B0 vert + 50% hand turn",
            "------\u2386 should feel some vert felt rip",
            "BH - slight topspin grip + wrists stay up",
          ],
          fireChanged: true,
          secondary: [
            "Play the ball for its entrance to contact zone",
            "Slice - side spin or high late down",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Mar 29, 2023 - Greg, doubles, Gavin"
        textContent={[
          "I'm continuing to volley better and better; I definitely feel more connected with my new racket than my previous ones - the most notable difference being my forehand volleys.",
          "My serving has also improved with the new racket; I'm able to hit my slice serves more consistently, and I am finding it pretty easy to place my kick serve out wide or down the T.",
          "I ordered some Weiss CANNON Ultra Cable 17 and Kirschbaum Spiky Black Shark 17 to try out for th next few string jobs.",
          "I signed up for a UTR event taking place at my club that takes place this weekend; two FAST4 matches, so that will be interesting.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - early back to 45\u00B0",
            "BH - extension + wrist wiper?",
          ],
          fireChanged: true,
          secondary: [
            "Play the ball for its entrance to contact zone",
            "Slice - side spin or high late down",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Mar 23, 2023 - Brett, Greg, doubles, Andrea"
        textContent={[
          "Two things are continuing to stay 'problems' for me:",
          "1) I have not figured out how I need to adjust when receiving deep flat shots to my forehand.",
          "2) I have not figured out how to upgrade my backhand - either with more pace, spin, or both.",
          "In regards to (1), I have continued in the cycle of finding something that seems to work for a period of time, and then it stops working and I try something else, and repeat.",
          "For (2), I have found that keeping my wrists 'up' (cocked) allows me easier access to power, but I get almost zero topspin despite trying to change my takeback and swing path in various ways.",
          "I strung one TFight 300 RS at 50lbs and one at 55lbs, and I think I'm leaning towards sticking with 55lbs for now.",
        ]}
        videos={[]}
        notes={{
          fire: ["FH - firm grip T-Rex?", "BH - wrists up?"],
          fireChanged: true,
          secondary: [
            "Play the ball for its entrance to contact zone",
            "Slice - wrist up & set, start high?",
            "Slice - side spin or high late down",
            "Return - deep position",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Mar 14, 2023 - Jim drill, doubles night, Demos/Rally with Greg"
        textContent={[
          "I did not think there was a chance I would pick something other than the ezone, but it happened. After multiple sessions on court with the Tecnifibre TFight 300 RS, it won me over. Both my forehand and serve were a little more potent than with the ezone, and no cons with my other strokes comparatively. The ezone is definitely the winner in terms of plushness and how good contact consistently feels. But, with the pros/cons leaning towards the TFight 300 RS, AND being litterally half the cost of the ezone, it was the winning pick.",
          "I took video of my most recent hitting session with Greg where I used the TFight for most of the practice, but swapped in the ezone a few different times.",
        ]}
        videos={[
          {
            title: "Rally Practice",
            iframeTitle: "Mar 14, 2023 - rally points",
            videoId: "JiXNpvPZ0-I",
          },
        ]}
        notes={{
          fire: [
            "FH - 'climb the steps' (square face long time)",
            "Flat to FH - closed stance",
            "BH - point tip to back fence",
          ],
          fireChanged: true,
          secondary: [
            "Watch the ball every moment",
            "Play the ball for its entrance to contact zone",
            "Return - deep position",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Mar 09, 2023 - Focus Points with Andrea"
        textContent={[
          "Focused on:",
          "1) finishing my forehand preparation sooner; experimented with using a loop.",
          "1) Having the tip of the racket point straight up in the follow through; also using straight arms seemed to be working well.",
        ]}
        videos={[
          {
            title: "Focus Points",
            iframeTitle: "Mar 09, 2023 - focus points",
            videoId: "hGk1CofWjcA",
          },
        ]}
        notes={{
          fire: [
            "FH - step in (prevents racket roll-over)",
            "FH - work on early prep",
            "BH - tip finishes pointing up, straight arms",
          ],
          fireChanged: true,
          secondary: ["FH Volley - watch contact in front, be long"],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Mar 05, 2023 - Andrea, doubles night, Greg, Tom drill"
        textContent={[
          "Andrea + doubles night - continued to enjoy hitting with the ezone; noticed even better serve accuracy when serving at doubles night.",
          "Greg - he demoed some rackets, sadly my new demos were not in yet. Of the rackets I own, I did determine my Dunlop Biomimetic F3.0 Tour Mid+ is my preferred racket, so I'll be playing with that until I decide on a new racket to buy. I experimented with staying deeper behind the baseline when able to, and it seemed like a net positive experience, so I'll be trying that out more moving forward.",
          "Tom drill - Used my Vcore Pro (my dunlop string snapped the day before and didn't feel like stringing it right away) and reaffirmed that the reduced maneuverability is too detrimental to my game to continue using it.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - stepping in OR serious body rotation prevents racket roll-over",
            "Flat Serve FH Return - pop and stop",
          ],
          fireChanged: true,
          secondary: ["FH Volley - watch contact in front, be long"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Mar2023;
