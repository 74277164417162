import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Feb2024() {
  return (
    <>
      <JournalEntry
        summary="Feb 23, 2024 - 4.5 Tournament, An - UTR Flex League"
        textContent={[
          "Last weekend I played in a <a href='https://app.utrsports.net/events/209504?d=e8bb66ad-8850-407a-bd95-9db5e9be79ba&r=0&t=4' target='_blank'>tournament 4.5 NTRP draw</a> where I played a round-robin format against three other guys.",
          "I won two of my three matches, with my loss being 4-6 3-6 against the guy who won all three of his matches. Overall I played solid compared to my practice level, so I was satisfied with my results. As usual, my forehand was relatively useless.",
          "A few days after the tournament I played my last 'regular season' match for the UTR flex league I have been playing. My opponent played 'first strike' tennis where a notable portion of the first strike came in the form of a drop shot or angled slice. This led to very few points where I was able to get a rhythm going during the point.",
          "",
        ]}
        videos={[
          {
            title: "An - UTR Flex League",
            iframeTitle: "Feb 23, 2024 - An - UTR Flex League",
            videoId: "nAqRa8yU-5k",
          },
        ]}
        notes={{
          fire: [
            "BH - hit the outside of the ball",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Feb 12, 2024 - Charles - UTR Flex League"
        textContent={[
          "I executed pretty effectively in my UTR flex league match against Charles. I committed to serve & volley almost every one of my service points, and both my serving and volleys were solid.",
          "I was able to manage the rhythm on my backhand well, allowing me to both neutralize and play some offense on that wing.",
          "I felt the biggest factor in our matchup was that his serve wasn't able to give me much trouble, so he had to play out rallies almost every point of his service games.",
          "",
          "",
          "",
        ]}
        videos={[
          {
            title: "Charles - UTR Flex League",
            iframeTitle: "Feb 12, 2024 - Charles - UTR Flex League",
            videoId: "VeXd87ZiwtA",
          },
        ]}
        notes={{
          fire: [
            "FH - need to throw the elbow",
            "BH - hit the outside of the ball",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Feb 09, 2024 - Scott - UTR Flex League"
        textContent={[
          "Played Scott in my second UTR Flex League match, and was notably less nervous and anxious than my first match. Being more relaxed definitely helped me feel more like a normal day on court, and felt like all aspects of my game were better than my last match.",
          "I had more unforced errors on my backhand than usual during this match as well as the last one, but I think that can mostly be chalked up to nerves and playing lefties, which as you can imagine I don't hit against very often.",
          "Another aspect that helped me relax a bit more was that Scott was trying to be more aggressive with his game than Trent was, and he was giving me enough unforced errors for me to know I didn't have to be perfectly consistent on my end.",
          "In the second set I decided to serve and volley and decent amount and was able to execute to a higher degree than I would have expected for not doing it much in matches as of yet.",
        ]}
        videos={[
          {
            title: "Scott - UTR Flex League",
            iframeTitle: "Feb 09, 2024 - Scott - UTR Flex League",
            videoId: "ig7JmY-XNQ4",
          },
        ]}
        notes={{
          fire: [
            "FH - Wrist held back at contact",
            "BH - swing the elbows + hands snap",
          ],
          fireChanged: false,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Feb 08, 2024 - Trent - UTR Flex League"
        textContent={[
          "Had my first UTR flex league match!",
          "I felt fine before getting on court, but once we started warming up, I was overtaken by the most nervous anxiety I have ever had on a tennis court, and it lasted <b><i>the entire match</i></b>.",
          "It was unquestionably the highest average heart rate I have ever had for the duration of a match. A symptom of this was my legs feeling like lead weights; my movement was slower and less dynamic than it had ever been.",
          "My goals going into it were to try and continue (trying) to play offensive tennis, even if my chances of winning would be higher if I relied on ugly tennis and my defense; for the most part, I was able to stick to my guns, even while the unforced errors kept climbing.",
          "In the end I lost 6-4 6-3. Trent had good movement and consistency, and my offense was too inconsistent to win the day.",
        ]}
        videos={[
          {
            title: "Trent - UTR Flex League",
            iframeTitle: "Feb 08, 2024 - Trent - UTR Flex League",
            videoId: "4FX4PZv8iSA",
          },
        ]}
        notes={{
          fire: [
            "FH - Wrist held back at contact",
            "BH - swing the elbows + hands snap",
          ],
          fireChanged: false,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Feb2024;
