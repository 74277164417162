import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Oct2023() {
  return (
    <>
      <JournalEntry
        summary="Oct 26, 2023 - Dan, Kris, Doubles Drill"
        textContent={[
          "One of the guys I had met at doubles drill reached out a few weeks ago to see about some singles practice, Kris, and we have practiced a few times since my last post. He is much more offensive minded than Dan; more unforced errors (also more winners), and our points are notably shorter.",
          "I've been committing to doing the Rafa (reverse forehand) finish on <b><i>every</i></b> forehand and have felt and seen a notable increase in consistency and quality of shot.",
          "I played my highest quality offensive set of tennis against Dan this week, using the Rafa finish, so I'm more hopeful than usual this could be more of a breakthrough for me than I usually think I have.",
          "My net game is continuing to improve as well, through lots of practice against Dan, as well as the doubles drill sessions.",
          "I've been watching a decent amount of the <b>Tennis Doctor</b>'s content on Youtube, as he has some different takes on learning technique.",
          "I ordered a reel of <b>Gosen Polylon</b> to try out for my crosses in the hopes of finding a decent cheap string that pairs nicely with my <b>Weiss Cannon Ultra Cable</b> in the mains.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH ? try eastern",
            "Use first two games to find FH range",
            "FH - loop rafa + 90% RHS + open stance",
            "(High - closed strings + more vert rafa)",
            "(Low - lift)",
            "BH - push top hand into bottom hand",
          ],
          fireChanged: true,
          secondary: ["FH - fix - 'show your back' OR loop"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Oct 03, 2023 - drills & sets with Dan"
        textContent={[
          "I'm continuing to find more forehand consistency, little by little. Near the start of the first set I had a string snap, and I had tried a different string setup in my other racket that I really did not like, to the point I cut out the string after being done with this session.",
          "Nothing too notable to report other than making slight adjustment to notes this time around.",
        ]}
        videos={[
          {
            title: "Drills & sets with Dan",
            iframeTitle: "Oct 03, 2023 - drills & sets with Dan",
            videoId: "lhSQ0ZgoQdo",
          },
        ]}
        notes={{
          fire: [
            "FH ? Open stance + closed shoulders",
            "FH ? experiment hitting flat 30% power",
            "FH - early prep",
            "FH - need wiper",
            "FH - wiper waist up, chip below",
            "BH - right handed forehand",
          ],
          fireChanged: true,
          secondary: [
            "FH - need closed shoulders prep",
            "Serve - jump",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Oct2023;
