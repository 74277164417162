import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Jan2023() {
  return (
    <>
      <JournalEntry
        summary="Jan 31, 2023 - ball machine, 2 Drills with Jim, Doubles night"
        textContent={[
          "Some time spent on the ball machine as well as serving practice, two drill sessions with Jim, and doubles night since the last post; all of it with the ezone I have had for the last week.",
          "Within around 100 serves I was able to hone in the adjustments I needed to make when serving with the ezone compared to my vcore pro; the core component being I needed to use my leg drive to compensate for any lacking power. The additional maneuverability of the ezone made it easier to time my contact, which led me to being able to really drive with my legs and still manage to time the contact at an even higher contact point than I usually do.",
          "Across the drill sessions and doubles night matchplay, I continued to feel improvement in all areas of the game compared to my current racket.",
          "I had a few technical elements come up during these sessions, some potential additions:",
          "Serve - higher toss, higher contact, legs",
          "BH - keep racket head in sight",
          "I am going to hold off on other alterations for now as they might be more relevant for the ezone vs my current racket.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - the earlier the better, upper strings",
            "Bh - slow and deep",
            "Return - split step === higher quality contact",
          ],
          fireChanged: true,
          secondary: ["Volley - straight arms, turn body"],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 28, 2023 - Drills with Jim, Racket demo with Greg"
        textContent={[
          "Another quality drill session with Jim; used my racket (VCORE Pro 97 330g (2018?)) strung at 40lbs both mains/crosses, and was too lively for me especially on my backhand and volleys; will be sticking at 45M/40C on this racket.",
          "I had mentioned thinking this my might be a little too heavy for me (effortful to continually get enough racket head speed to impart enough spin for control), and so I had ordered a few rackets to demo:",
          "Yonex EZONE 98 (305g)",
          "Head Graphene 360+ Extreme Tour",
          "Head Graphene 360+ Extreme MP",
          "Both Head rackets seemed to preform inline with what I had seen from racket reviewers - the Extreme Tour felt best when hitting more flat, and did not work well for me when trying to hit the loopier shot I prefer. The Extreme MP did not work well for me when trying to hit flatter, and felt notably better when hitting my loopier forehand. I didn't add any weight to them, and stock I felt they lacked the stability I'm use to with my current racket. Although I'm willing to play around with customizing, I would still want a racket to feel like a better overall fit for me right off the bat if I was going to spend the money to switch rackets.",
          "The EZONE 98 on the other hand, felt great right from the start. I was able to create the shape of shot I wanted without thinking about it, the spin was great, slicing was easier, and the stability was there. I was also able to hit quality shots from awkward/reaching positions much more so than my current racket as well. I'm only going to use the ezone for the remainder of my demo time. The only area in which the loss of racket weight felt detrimental was for my serve; I definitely get less 'free' power, so I plan on serving some buckets of balls to see if I feel like I can make the adjustment to my serve swing/motion to minimize the lack of free power.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - the earlier the better, upper strings",
            "Bh - slow and deep",
          ],
          fireChanged: false,
          secondary: [
            "Flat Serve - elbow slap, toss into court",
            "Volley - straight arms, turn body",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 23, 2023 - Practice sessions, doubles night"
        textContent={[
          "My buddy Brett was in town for a handful of days over the past week, and we got in over six hours of practice over three sessions on court. For the most part we stuck with playing out points starting with a neutral ball feed. All in all, it was just a lot of good quality practice reps.",
          "From a technique perspective, I went back to not trying to blast backhand winners, and so I didn't have any issues with it breaking down throughout our practice. I didn't have any noteworthy issues with my forehand; mostly just continuing to experiment with what small adjustments I need to make to further improve consistency, and what pieces to change when I want to flatten out the ball more.",
          "Now that my forehand has solidified to the point where I can trust it, I spent some time trying out most of the rackets I own, to see how the differences in weight/balance changed my game. The end result was me thinking my current racket might be a little too heavy for me to generate the minimum-optimal racket head speed at my 70% effort; I was getting a notable amount more racket head speed with some of the lighter rackets. There were definite pros & cons with some of my rackets, so after the weekend was over, I ordered a few rackets to demo in the coming week or two.",
          "Played pretty well at my clubs doubles night; I think my biggest takeaway was that I was seeing much better success on returns, as I decided to try out hitting more 'drive' returns as opposed to the little more loopy returns I usually hit during my singles matches. I'm going to try being a little more offensive wih returns in my next singles match.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - the earlier the better, upper strings",
            "Bh - slow and deep",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - elbow slap, toss into court",
            "Volley - straight arms, turn body",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 16, 2023 - Rallying, drill with coach"
        textContent={[
          "I focused on increasing my average swing speed from 70% to 80% and aiming for slightly higher contact on strings, and didn't notice a drop in consistency; will see how it goes in match play.",
          "Going to shift back to my Proven backhand note; I've found myself trying to blast backhands now that my forehand has improved, and have not been doing it effectively.",
          "During the drill session with the coach, he told me I need to keep my body/feet active when waiting for 'lob' type balls to my forehand, as I currently lose all my intensity when waiting.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - 80% swing speed, upper strings",
            "Fh - long rainbow?",
            "Lob to FH - happy feet? small loop?",
            "Bh - slow and deep",
          ],
          fireChanged: true,
          secondary: [
            "Flat Serve - slow elbow slap forward",
            "Serve - flat/slice - toss into court, slow swing",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 14, 2023 - Mic'd Set with Greg"
        textContent={[
          "My forehand held up well, but lack of depth gave him too many offensive opportunities.",
          "I need to shift some focus towards working on my backhand technique.",
        ]}
        videos={[
          {
            title: "Mic'd Set with Greg",
            iframeTitle: "Jan 15, 2023 - Mic'd Set with Greg",
            videoId: "DDkElQix6pA",
          },
        ]}
        notes={{
          fire: [
            "FH - racket tip 7pm, no backswing, 80% swing speed, upper strings",
            "Lob to FH - small loop? T-Rex?",
            "low to FH - golf swing",
          ],
          fireChanged: true,
          secondary: [
            "Bh - later contact?",
            "Serve - flat/slice - toss into court, slow swing",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 12, 2023 - Drills with Jim"
        textContent={[
          "I made very few volley errors today; I went back to not trying to punch volleys, and although the average volley was less potent, I definitely won a larger percentage of points, even with having to make more follow up volleys and overheads.",
          "Many less errors when receiving low fast balls to my forehand today; using the 'golf swing' path was providing a nice level of control.",
          "When inside the court receiving soft balls, using a wiper was giving a high degree of directional control, and worked nicely as a passing shot.",
          "When receiving lobs to my forehand, keeping my elbow closer to my body was working well.",
          "Using more wiper on the backhand also felt good today; in baseline rallies I found it easy to hit deep high bouncers, and it worked nicely for passing shots as well.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - Racket tip 7pm, no backswing",
            "BH - wiper slow balls, redirect fast balls",
            "Serve - flat/slice - toss into court, slow swing",
          ],
          fireChanged: true,
          secondary: [
            "Lob to FH - slow t-rex",
            "low to FH - golf swing",
            "80% FH - higher contact on strings",
          ],
          secondaryChanged: true,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - Left foot angled left, high toss over head",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: true,
        }}
      />

      <JournalEntry
        summary="Jan 10, 2023 - Rallying with Andrea"
        textContent={[
          "Thinking 'no backswing' on the forehand continued to work well (higher consistency, and closer to intended ball flight) today.",
          "Specifically when swinging harder (80% vs 70%), trying to make higher contact on the racket or having a higher finish seemed to be helping.",
          "Also tried to experiment with handling low forehands; thinking swing straight ahead seemed promising.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - Racket tip 7pm, no backswing",
            "BH - make tip point down at some point",
            "Serve - flat/slice - toss into court",
          ],
          fireChanged: true,
          secondary: [
            "80% FH - ? - higher contact? higher finish?",
            "low FH - ? - swing straight ahead",
            "FH - don't 'lock' the body",
          ],
          secondaryChanged: true,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 07, 2023 - Drills With Jim"
        textContent={[
          "Delaying the punch on volleys was still working nicely again today. Tossing into the court on flat/slice serves seemed noteworthy again. Ensuring the tip of the racket pointed down at some point on my backhand was giving a more consistent shape to my shots. Thinking 'no backswing' seemed to improve forehand consistency.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - Racket tip 7pm, no backswing",
            "BH - make tip point down at some point",
            "Serve - flat/slice - toss into court",
            "Volley - delay punch",
          ],
          fireChanged: true,
          secondary: [
            "All Strokes (-dropshot) - firm grip",
            "FH - don't 'lock' the body",
          ],
          secondaryChanged: false,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 06, 2023 - Drills With Jim, sets with Greg"
        textContent={[
          "Drill session with Jim; takeaways - punching late on volleys is better than being early; point the tip down at end of backhand prep.",
          "Greg Set 1 - Intentionally didn't shy away from FH/FH rallies, I wanted to see how things would go, and how it would alter my own forehand results.",
          "Greg set 2 - I was not adapting my forehand prep/swing timing/tempo well enough in FH/FH rallies in the first set, so played more to his backhand to allow me more forehand reps at a more comfortable tempo.",
          "I need to focus on ensuring my prep phase is done on time (or early), as I think I was consistently late when receiving strong shots from his forehand.",
        ]}
        videos={[
          {
            title: "Sets with Greg",
            iframeTitle: "Jan 05, 2023 - Sets with Greg",
            videoId: "EiaqFTBGYZg",
          },
        ]}
        notes={{
          fire: [
            "Groundies - Racket tip to 7pm at end of prep phase",
            "Groundies - ? - finish preparation early",
            "Volley - delay punch",
          ],
          fireChanged: true,
          secondary: [
            "All Strokes (-dropshot) - firm grip",
            "FH - don't 'lock' the body",
          ],
          secondaryChanged: true,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Jan 04, 2023 - Rallying with Jack"
        textContent={[
          "Unfortunately there were technical issues that prevented me from recording the session.",
          "I was able to hit with someone who plays 5.0 USTA, and it was an experience. Within 5 minutes of feeding in point play, I was winded continuously until I asked for a break probably 15 minutes in.",
          "I noted during the water break that I had subconsciously been lulled intro trying to match his swing speed and pace of shot, which of course I can't manage, and so had much lower consistency than usual, and even when I did manage to match his quality of shot, he didn't have any issue handling it.",
          "As I had noted on Dec 21, 2022, trying to hit with pace was causing more errors on both wings, and I lost control of depth, which allowed him an approach shot. And once again, he handled the pace without issue.",
          "The second (and final) session I went into with a few focus points: (1) Hit within my ability (only hit MY 70% shot), (2) depth not pace, and (3) stay in cross court patterns (to run less as well as force a little more risk on his end).",
          "My stamina was able to last notably (~5-10 minutes) longer; my percentage of points won went from horrendous in the first session to just terrible in the second; I had a little bit more time on average receiving each shot which made a big difference - in part allowing me to notice and engage more rallies of my forehand to his backhand, which I had at least a small advantage on.",
          "Another important note was that even though my 70% forehands were notably slower than his, I was still able to gain offense with it, so even more reason not to try to match the pace of better players right now.",
          "I realized that as the sessions went on, I started hitting more and more forehands into the net, which hasn't been an issue for me for a while - I had to actively try to both add more shape to my shot as well as finish higher, due to the fatigue.",
          "I think my Focus points are definitely worth keeping in mind when I get to hit with better players, but for now I'm not going to alter my Notes with these lessons learned.",
          "A final thought - I didn't have issues with my form or technique breaking down inherently - only when my timing was off due to fatigue or trying to hit with too much pace did I hit poor shots; in hindsight I'm pretty pleased with that, as it should hopefully mean my foundational technique has improved.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - Racket tip to 7pm at end of prep phase",
            "Deep low FH - ? - wiper",
            "FH Volley - delay punch",
          ],
          fireChanged: false,
          secondary: [
            "All Strokes (-dropshot) - firm grip",
            "FH - don't 'lock' the body",
            "Flat Serve - swing slow",
          ],
          secondaryChanged: false,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Jan2023;
