import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Dec2022() {
  return (
    <>
      <JournalEntry
        summary="Dec 31, 2022 - Drills with Jim"
        textContent={[
          "Most drills were focused around approach and net play; a good portion of serve & volley towards the end.",
          "I had consistent trouble when receiving deep low shots to my forehand (usually from punch volleys); a few wiper forehands were working, so going to have that be a plan to try out more moving forward.",
          "Delaying my 'punch' on my forehand volleys was working well; my average quality of shot was notably higher (control, depth, feel of contact) than usual.",
          "Played around a little with backhand preparation, but going to drop it off my focus for now.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - Racket tip to 7pm at end of prep phase",
            "Deep low FH - ? - wiper",
            "FH Volley - delay punch",
          ],
          fireChanged: true,
          secondary: [
            "All Strokes (-dropshot) - firm grip",
            "FH - don't 'lock' the body",
            "Flat Serve - swing slow",
          ],
          secondaryChanged: true,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Dec 30, 2022 - Rallying with Andrea"
        textContent={[
          "Forehand focus today, continuing to get more reps of stepping around forehands as well.",
          "Racket tip to 7pm was the only active focus point all session, and it felt good the whole time - I also didn't 'close' my racket face after contact on a single shot today, so might be improving a few aspects simultaneously.",
          "Looking over some of the footage, I'm doing a much better job of not 'locking' my body as I swing, not having my off-arm cross my body, in general it's looking more natural (although I'm barely utilizing my legs it appears).",
          "The previous Fire backhand tip wasn't feeling great, so probably putting that aside for now; going to stick with my usual grip and keep the other backhand note in mind for next time.",
        ]}
        videos={[
          {
            title: "Practice Session",
            iframeTitle: "Dec 30, 2022 - Practice Session",
            videoId: "gWz4RrZOz2c",
          },
        ]}
        notes={{
          fire: [
            "FH - Racket tip to 7pm at end of prep phase",
            "BH - ? - hands closer to hip in prep phase",
          ],
          fireChanged: true,
          secondary: [
            "All Strokes (-dropshot) - firm grip",
            "FH - don't 'lock' the body",
            "Flat Serve - toss at 11, swing slow, slightly into court",
          ],
          secondaryChanged: true,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Dec 28, 2022 - Rallying with Andrea"
        textContent={[
          "Usual baseline feed rallies; working on shot quality in general, reps of approach shot footwork and net play, reps of stepping around for forehands.",
          "Experimented a little with my Secondary backhand notes, wasn't immediately working for me, but will give it another practice session before dropping.",
          "In regards to forehands - Dropping the 1st Fire note as stepping into the ball isn't always an option (and trying to force it today was not fruitful), dropping the 2nd Fire note clearing off-arm elbow (heavily tied to the Secondary note of just making sure I don't lock my body), and altering/updating the 3rd Fire note; It was only towards the end of the session, but was feeling good results when I just focused on getting the racket tip pointing towards 7pm at the end of my prep phase.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - ? - Racket tip to 7pm at end of prep phase",
            "BH - ? - right hand base knuckle semi western",
          ],
          fireChanged: true,
          secondary: [
            "All Strokes (-dropshot) - firm grip",
            "FH - don't 'lock' the body",
            "BH - ? - hands closer to hip in prep phase",
            "Flat Serve - toss at 11, swing slow, slightly into court",
          ],
          secondaryChanged: true,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Dec 27, 2022 - Drills with Jim + coach"
        textContent={[
          "Another drill session with Jim; 45 min of him and I doing drills like last time, and 45 min with drills led be a coach.",
          "All in all pretty satisfied with my execution; other then a warm-up period of missing like 8 forehands in a row, it stayed reliable and solid. When I got fatigued, I did notice having the issue of having the racket face closing over a handful of times, so going to re-focus on preventing that for next time; going to focus back on stepping into the shot and see if it prevents that from occurring.",
          "My volleys are continuing to feel better little by little; ensuring I'm moving through the volley worked well again today, even with the higher level of difficulty then I'm use to.",
          "The pace of play was too high for me to focus on backhand alterations, so I didn't get a chance to actively work on trying an easter backhand grip, nor actively trying to keep my hands closer to my hip in the preparation phase.",
          "I looked into a few key notes for flat serves (which I haven't ever  really utilized in matches), and was finding some decent rhythm with the few key notes I had made going into today, and have them added as secondary notes moving forward.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - ? - step into shot (prevents pulling up with body/head)",
            "FH - ? - clear the off-arm elbow",
            "FH - ? - RH lower in prep phase",
          ],
          fireChanged: true,
          secondary: [
            "All Strokes (-dropshot) - firm grip",
            "FH - don't 'lock' the body",
            "BH - ? - eastern grip",
            "BH - ? - hands closer to hip in prep phase",
            "Flat Serve - toss at 11, swing slow, slightly into court",
          ],
          secondaryChanged: true,

          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "BH - Depth not pace",
            "Volley - Don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up, start high",
            "FH approach - hop step",
          ],
          provenChanged: true,
        }}
      />

      <JournalEntry
        summary="Dec 23, 2022 - Drill session with Jim"
        textContent={[
          "Jim is one of the rare people who make a point of being very intentional with their practice sessions; I'll outline the routine we went through. We do each drill for ~10-15 minutes, and always play to a win condition, which keeps intensity and focus high.",
          "Mini tennis warm up; volley drill DTL - start at service line, try to close the net, no lobs; volley drill DTL - stand behind service line, volleys must land past service line; full court down the middle rally (no scoring); ad side CC FH vs BH, can approach on short balls; deuce side CC BH vs FH, can approach on short balls; FH vs BH DTL, can approach on short balls; BH vs FH DTL, can approach on short balls; CC serve and volley, anything goes.",
          "LOTS of volleys in this routine, so no surprise I came away with two volley notes - using a firm (6-7 out of 10) grip still produces the best combination of shot quality and consistency, so reaffirming that proven volley note. Secondly, when time permitted, making sure my feet were moving mid-volley (usually moving body forward) allowed me to do less with my arm and still produce good results, so going to be keeping that in mind.",
          "I was also keeping my previous Analysis notes in mind - I was trying to keep my serve motion fluid and that still felt good; I prevented myself from trying to hit with too much pace on the backhand; I ensured I didn't 'lock' my body on forehands by swinging too hard (arm tension).",
          "For my backhand, I was experimenting with using an eastern BH grip (I'm usually continental), and keeping my hands closer to my hip in the prep phase.",
          "And finally some notes just for the forehand. Not much 'new', but some altering of prioritization - arm tension and 'locking' my body are tied together, and today just making sure I 'cleared' my off-arm on forehands seemed to prevent those issues from occurring, so I'm going to reduce those considerations down to the one that prevents the other two, to help limit focus points.",
          "The one new forehand note is something that was feeling good, but needs more time to test out - getting my racket head lower in my preparation phase was helping produce a really nice shape of shot, that also increased the feeling of trust I wouldn't hit long.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "All Strokes (-dropshot) - firm grip",
            "FH - clear the off-arm elbow",
            "FH - ? - RH lower in prep phase",
            "BH - ? - eastern grip",
            "BH - ? - hands closer to hip in prep phase",
            "Volley - move through contact",
          ],
          fireChanged: true,
          secondary: ["FH - don't 'lock' the body", "FH - hop step approach"],
          secondaryChanged: true,
          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "Return - Firm grip",
            "BH - Depth not pace",
            "Volley - Don't swing, Firm grip",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - firm grip, wrist up, start high",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Dec 21, 2022 - Analysis"
        textContent={[
          "After watching over footage of my last two matches, I had a few items I felt were worth focusing on:",
          "1) When I try to swing too hard with my arm (tension), I don't have shoulder rotation, and the result is being too closed with my body at contact - it looks like I'm being tased.",
          "2) When I try to hit with pace on my backhand, I lose control of depth, and my consistency goes down.",
          "3) There is a small hitch on my serve right around my trophy pose, so I'd like to see about making it a little more fluid.",
        ]}
        videos={[
          {
            title: "Focus Points",
            iframeTitle: "Dec 21, 2022 - Focus Points",
            videoId: "5KTgg6VjfIs",
          },
        ]}
        notes={{
          fire: [
            "FH - don't let arm feel tension",
            "FH - clear the off-arm elbow?",
          ],
          fireChanged: true,
          secondary: [
            "FH - hop step approach",
            "FH - Wrist kept set is contact foundation",
            "FH - Firm grip is stable contact",
          ],
          secondaryChanged: true,
          proven: [
            "Serve - Left foot angled left, high toss over head, fluid",
            "Return - Firm grip",
            "BH - Depth not pace",
            "Volley - Don't swing, Firm grip",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - firm grip, wrist up, start high",
          ],
          provenChanged: true,
        }}
      />

      <JournalEntry
        summary="Dec 20, 2022 - Match, Set 2"
        textContent={[
          "I was happy with my level of execution overall, and given the results, it didn't make sense to consider changing tactics until I needed to.",
        ]}
        videos={[
          {
            title: "Set 2",
            iframeTitle: "Dec 20, 202 - Match Set 2",
            videoId: "u4lnNFpJ3yU",
          },
        ]}
        notes={{
          fire: [
            "FH - Need weight transfer for neutral stance, trunk rotation for open stance",
            "FH - hop step approach",
          ],
          fireChanged: false,
          secondary: [
            "FH - Wrist kept set is contact foundation",
            "FH - Firm grip is stable contact",
          ],
          secondaryChanged: false,
          proven: [
            "Serve - Left foot angled left, high toss over head",
            "Return - Firm grip",
            "BH - Keep wrists set",
            "Volley - Don't swing, Firm grip",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - firm grip, wrist up, start high",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Dec 19, 2022 - Match, Set 1"
        textContent={[
          "Playing a fellow lefty!",
          "I was pretty happy with my execution during my match with Brian, and didn't have any notes I felt needed to be changed.",
          "His forehand is a little more consistent than mine and doesn't break down if he gets in position, so when I stay in a forehand cross-court rally with him, I put extra emphasis on my weight-of-shot, or angle off the court.",
          "His backhand has improved a notable degree this summer, but I think I have the advantage in backhand exchanges.",
        ]}
        videos={[
          {
            title: "Set 1",
            iframeTitle: "Dec 19, 2022 - Match Set 1",
            videoId: "TtWUuJT-m6s",
          },
        ]}
        notes={{
          fire: [
            "FH - Need weight transfer for neutral stance, trunk rotation for open stance",
            "FH - hop step approach",
          ],
          fireChanged: false,
          secondary: [
            "FH - Wrist kept set is contact foundation",
            "FH - Firm grip is stable contact",
          ],
          secondaryChanged: false,
          proven: [
            "Serve - Left foot angled left, high toss over head",
            "Return - Firm grip",
            "BH - Keep wrists set",
            "Volley - Don't swing, Firm grip",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - firm grip, wrist up, start high",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Dec 13, 2022 - Match, Set 2"
        textContent={[
          "I was able to execute my game plan well in the first set, so there was no reason to change things going into the second.",
          "My fire notes weren't leading me astray, so no alterations.",
        ]}
        videos={[
          {
            title: "Set 2",
            iframeTitle: "Dec 13, 2022 - Match Set 2",
            videoId: "42DbIL8QksM",
          },
        ]}
        notes={{
          fire: [
            "FH - Need weight transfer for neutral stance, trunk rotation for open stance",
            "FH - hop step approach",
          ],
          fireChanged: false,
          secondary: [
            "FH - Wrist kept set is contact foundation",
            "FH - Firm grip is stable contact",
          ],
          secondaryChanged: false,
          proven: [
            "Serve - Left foot angled left, high toss over head",
            "Return - Firm grip",
            "BH - Keep wrists set",
            "Volley - Don't swing, Firm grip",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - firm grip, wrist up, start high",
          ],
          provenChanged: false,
        }}
      />

      <JournalEntry
        summary="Dec 11, 2022 - Match, Play-by-Play, Set 1"
        textContent={[
          "I played a few sets against one of my regular hitting partners and edited video for the first set.",
          "I actively keep multiple different priority-level tennis notes on my phone; during matches I make sure to only reference my top priority items (if at all) to prevent myself from trying to focus on too many things. I'll track my notes in these posts as well, and indicate when items in a list change from a previous post.",
        ]}
        videos={[
          {
            title: "Set 1",
            iframeTitle: "Dec 11, 2022 - Match Set 1",
            videoId: "IkaA4_91U3c",
          },
          {
            title: "Play-by-Play",
            iframeTitle: "Dec 11, 2022 - Set 1 Play-by-Play",
            videoId: "gbRCa9y8n5I",
          },
        ]}
        notes={{
          fire: [
            "FH - Need weight transfer for neutral stance, trunk rotation for open stance",
            "FH - hop step approach",
          ],
          fireChanged: false,
          secondary: [
            "FH - Wrist kept set is contact foundation",
            "FH - Firm grip is stable contact",
          ],
          secondaryChanged: false,
          proven: [
            "Serve - Left foot angled left, high toss over head",
            "Return - Firm grip",
            "BH - Keep wrists set",
            "Volley - Don't swing, Firm grip",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - firm grip, wrist up, start high",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Dec2022;
