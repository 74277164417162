import JournalEntry from "../../shared/journal-entry/JournalEntry";

function May2024() {
  return (
    <>
      <JournalEntry
        summary="May 17, 2024 - Dan, Julian, Steve, John, BM"
        textContent={[
          "Outside of me continuing to neglect utilizing my body more effectively on my forehand, <b>Tom's</b> biggest issue with my racket arm itself is that the racket head gets way too far below the eventual contact point.",
          "I had noticed this as well, as any pro (even with more western grips) does not have nearly as steep of a vertical swing path as I do - but having Tom call it out gave me more reason to bring this to the top of my priority list.",
          "I've been having decent success with my current 'hand turn' forehand, but there is still too much inconsistency - which I'm hoping if I can solve the racket head drop problem, I will gain the consistency I'm looking for.",
          "Hit on the ball machine today, hitting between 10-15 balls while recoding in slow motion, trying something different each time, and checking to see if both the quality of my shots felt good, and if I had been able to reduce the amount of racket drop.",
          "After a dozen rounds or so, I felt decent enough with what I was trying to send Tom this clip, and will see what his thoughts are.",
        ]}
        videos={[
          {
            title: "Ball Machine",
            iframeTitle: "May 17, 2024",
            videoId: "L3LGUzAOTuU",
          },
        ]}
        notes={{
          fire: ["FH - tip straight up leads into hand turn right"],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="May 09, 2024 - More of the Same"
        textContent={[
          "I have continued to get a good volume of court time in, between hitting with the ball machine, hitting partners, and playing a handful of sets.",
          "As usual, I felt like I had made progress with my forehand, but have not been able have the same 'technique' hold up practice after practice, much less in match play.",
          "My current forehand focus is on trying to have a full stroke every time; historically I have put most of my emphasis into the contact point, which seems to lead to my lack of consistent stability.",
        ]}
        videos={[
          {
            title: "Drills",
            iframeTitle: "May 09, 2024",
            videoId: "RQtCk8NRI6I",
          },
        ]}
        notes={{
          fire: ["FH/BH - full stroke (tap back)"],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default May2024;
