import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Sep2023() {
  return (
    <>
          <JournalEntry
        summary="Sep 20, 2023 - Dan"
        textContent={[
          "Another practice session with Dan. After watching over video of our previous session, I felt like not finishing my preparation early enough led to hitting the ball late frequently.",
          "With that in mind my focus this session was first and foremost to ensure I finished my preparation sooner, and experimenting as necessary to figure out which way of preparing earlier led to the best results for me.",
          "After watching over this session of hitting, I think I definitely did a better job making sure I was finished with my preparation earlier.",
          "There are two things that stood out to me to focus on for next session - prevent arming the ball somehow, tied in with ensuring I have spacing between my elbow and body during the swing.",
          "I think my best forehands are my inside out forehands due to the fact that in order to hit inside out you end up having to swing more outwards away from your body, so I need to find a way to replicate that on all forehands.",
          "So next session I will be trying to work on keeping my elbow away from my body, and or trying to have my upper body rotation play a bigger part in the swing.",
        ]}
        videos={[
          {
            title: "Hand fed games with Dan",
            iframeTitle: "Sep 20, 2023 - Hand fed games with Dan",
            videoId: "0eXxzigpgcM",
          },
        ]}
        notes={{
          fire: [
            "FH ? elbow away from body",
            "FH - early prep",
            "BH - pull cap into contact + over shoulder",
            "FH - need closed shoulders",
            "FH - need lag explode",
            "FH - wiper waist up, lift knee up, chip below",
          ],
          fireChanged: true,
          secondary: [
            "Serve - jump",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Sep 16, 2023 - Tom, Tom/dubs, Srini, Dan"
        textContent={[
          "I've been able to find a few more practice partners lately which has been great.",
          "I have been liking the <b>weiss cannon ultra cable</b> a lot and committed to ordering a reel of it. The bite on the ball is massive and gives me extra confidence (conviction) to swing out on my strokes which is a big deal for my mentality.",
          "Dan had mentioned also being interested in taking some footage of us hitting, so I took him up on the offer to get some video for the first time in a while.",
          "We played hand fed full-court games to 11 for the majority of our practice session this morning, but finished our session with playing a set.",
          "I was really pleased with my conviction to my forehand today, and as always, think I'm onto a few things that might be improvements for my forehand.",
          "In regards to Nik's (from <b>intuitive tennis</b>) video analysis of my forehand, my current focus is on his note of needing to 'show my back' in the preparation phase; my current thought is that I consistently 'open' my body towards contact <b>too early</b> leading to pulling off the ball and lifting my head too soon. My mental note that has been working to counter that issue has been telling myself to get into a closed stance on every forehand, and trust my body to intuitively rotate as necessary.",
        ]}
        videos={[
          {
            title: "Hand fed games & a set with Dan",
            iframeTitle: "Sep 16, 2023 - Hand fed games & a set with Dan",
            videoId: "RbbgUnBrbkQ",
          },
        ]}
        notes={{
          fire: [
            "FH - need closed stance",
            "FH - need lag explode",
            "FH - wiper waist up, lift knee up, chip below",
            "BH - eastern + finish over shoulder + slow depth",
          ],
          fireChanged: true,
          secondary: [
            "Serve - jump",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Sep 08, 2023 - More practice lately!"
        textContent={[
          "I rediscovered how valuable finishing the stroke over the shoulder is for my backhand, leading to immediate improvement in the recent weeks.",
          "I experimented with stringing my racket at 60lbs for one string job in the hopes of trusting I wouldn't hit forehands long; end result was that I didn't feel I was gaining any forehand benefit and it definitely made the rest of my strokes worse.",
          "As always, continuing to try to hone in my forehand consistency. I have an easier time hitting forehands down the line so I've started going with that shot over hitting cross court more often to try and help find my feel earlier in the practice or sets.",
          "I'm going to experiment with having different forehand swings for more down the line vs using a rafa forehand for cross court.",
        ]}
        videos={[]}
        notes={{
          fire: [
            "FH - fully loose wrist",
            "FH ? one degree vert flat",
            "FH ? rafa for CC",
            "BH - eastern + finish over shoulder",
          ],
          fireChanged: true,
          secondary: [
            "Serve - jump",
            "FH - fix - 'show your back' OR loop",
            "FH/BH - wider stance",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Sep2023;
