import JournalEntry from "../../shared/journal-entry/JournalEntry";

function Mar2024() {
  return (
    <>
         <JournalEntry
        summary="Mar 29, 2024 - Julian, Dan, Ball Machine"
        textContent={[
       "I feel like I have made some progress with understanding what my hand and wrist need to be doing throughout the preparation and stroke, as well as the value of having the elbow be away from the body for the stroke.",
       "As far as visual checkpoints go, I am no longer having my strings face the side fence during the follow through some of the time - now (essentially) every forehand follow through has the strings continue to face the target.",
        ]}
        videos={[
          {
            title: "Forehands",
            iframeTitle: "Mar 29, 2024",
            videoId: "tjk3hD-kgcI",
          },
        ]}
        notes={{
          fire: [
            "FH - contact is outside straight off-arm",
            "FH - wrist starts and stays cocked (extended)",
            "FH - hand turn 9 --> 3",
            "FH ? elbow punch up & out",
            "FH ? 'decel' into contact",
            "BH - hit the outside of the ball",
        ],
          fireChanged: true,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
      <JournalEntry
        summary="Mar 19, 2024 - Connor, Julian, Carter, Dan, Tom from TPA Tennis"
        textContent={[
          "In the last few weeks I made a few new contacts, stemming from both the tournament I had played in as well as the doubles drill I occasionally attend.",
          "Between my additional new hitting partners and time with the ball machine, I have had plenty of time on court since my last entry.",
          "Another notable update is that I decided to sign up for ongoing video analysis with <a href='https://tpatennis.com' target='_blank'>Tom from TPA Tennis</a>. We have had our initial zoom call as well as multiple updates/responses already, with goal of trying to improve my forehand.",
          "Adding the video I took this morning while using the ball machine.",
        ]}
        videos={[
          {
            title: "FH for Tom",
            iframeTitle: "Mar 19, 2024",
            videoId: "ooqipsZstAY",
          },
        ]}
        notes={{
          fire: ["BH - hit the outside of the ball"],
          fireChanged: false,
          secondary: [
            "Flat Serve - 30% arm slap & jump",
            "FH - fix - 'show your back' OR loop",
          ],
          secondaryChanged: false,

          proven: [
            "FH - don't 'lock' the body",
            "Serve - left foot angled left, high toss over head",
            "BH - depth not pace",
            "Volley - don't swing, move feet through the shot",
            "Overhead - slow swing",
            "Dropshot - soft grip",
            "Slice - wrist up & set, start high",
            "FH approach - hop step",
          ],
          provenChanged: false,
        }}
      />
    </>
  );
}
export default Mar2024;
